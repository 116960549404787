import { formatDateMT } from './dateHelpers'
import { formatTime, stripTZ } from './dateHelpers'

function recoveryModelTrace(recoveryData, barColor) {
  return {
    x: recoveryData.map((item) => item.shift_label),
    y: recoveryData.map((item) => item.prediction),
    type: 'bar',
    name: 'Model',
    marker: {
      color: barColor,
    },
  }
}

function recoveryActualTrace(recoveryData, barColor) {
  return {
    x: recoveryData.map((item) => item.shift_label),
    y: recoveryData.map((item) => item.lab),
    type: 'bar',
    name: 'Actual',
    marker: {
      color: barColor,
    },
  }
}

function recoveryDailyPlanTrace(recoveryData, lineColor) {
  return {
    x: recoveryData.map((item) => item.shift_label),
    y: recoveryData.map((item) => item.daily_plan),
    type: 'line',
    name: 'Row Plan',
    marker: {
      color: lineColor,
    },
  }
}

function tagStatTrace(tagStatsData, lineColor, chartDataType) {
  return {
    x: tagStatsData.map((item) => item.timestamp_mt),
    y: tagStatsData.map((item) => item[chartDataType]),
    type: 'line',
    name: chartDataType,
    marker: {
      color: lineColor,
    },
  }
}

function tagStatsShadedAreaShape(
  selectedExplainerData,
  tagStatsData,
  historicalAggregates,
  theme
) {
  return {
    type: 'rect',
    // x-reference is assigned to the x-values
    xref: 'x',
    // y-reference is assigned to the plot paper [0,1]
    yref: 'y',
    x0: tagStatsData[0].timestamp_mt,
    y0:
      selectedExplainerData.lower !== null
        ? selectedExplainerData.lower
        : historicalAggregates.mean - historicalAggregates.sd * 3,
    x1: tagStatsData[tagStatsData.length - 1].timestamp_mt,
    y1:
      selectedExplainerData.upper !== null
        ? selectedExplainerData.upper
        : historicalAggregates.mean + historicalAggregates.sd * 3,
    fillcolor: selectedExplainerData.negatively_impacting
      ? theme.brandColors.rioTintoRed
      : theme.brandColors.secondaryGreen,
    opacity: 0.2,
    line: {
      width: 0,
    },
  }
}

function tagStatsStandardDeviationUpperTrace(
  tagStatsData,
  historicalAggregates,
  theme
) {
  return {
    x: tagStatsData.map((item) => item.timestamp_mt),
    y: tagStatsData.map(
      () => historicalAggregates.mean + historicalAggregates.sd * 3
    ),
    type: 'line',
    name: '+3SD',
    hoverinfo: 'none',
    line: {
      color: theme.brandColors.secondaryOrange,
      width: 3,
    },
  }
}

function tagStatsStandardDeviationLowerTrace(
  tagStatsData,
  historicalAggregates,
  theme
) {
  return {
    x: tagStatsData.map((item) => item.timestamp_mt),
    y: tagStatsData.map(
      () => historicalAggregates.mean - historicalAggregates.sd * 3
    ),
    type: 'line',
    name: '-3SD',
    hoverinfo: 'none',
    line: {
      color: theme.brandColors.secondaryOrange,
      width: 3,
    },
  }
}

function shiftChangeShape(selectedShiftStartTimestamp, theme) {
  const shiftChangeMountainTime = formatDateMT(selectedShiftStartTimestamp)

  return {
    type: 'line',
    xref: 'x',
    yref: 'paper',
    x0: shiftChangeMountainTime,
    y0: 0,
    x1: shiftChangeMountainTime,
    y1: 1,
    line: {
      color: theme.brandColors.secondaryLightGreen,
      width: 3,
    },
    name: 'Shift Change',
  }
}

function explainerShiftLabel(explainerData, recoveryData) {
  const explainerTimestamp = explainerData[0].timestamp_utc
  const recoveryForExplainer = recoveryData.filter((recovery) => {
    return (
      formatTime(stripTZ(recovery.timestamp_utc)) ===
      formatTime(stripTZ(explainerTimestamp))
    )
  })
  if (recoveryForExplainer === undefined || recoveryForExplainer.length === 0) {
    return ''
  }
  // TODO show actual prediction time for current shift
  return recoveryForExplainer.pop().shift_label
}

function recoveryTimeStampFromLabel(recoveryData, shiftLabel) {
  const recoveryForShiftLabel = recoveryData.filter((recovery) => {
    return recovery.shift_label === shiftLabel
  }).pop()
  return [recoveryForShiftLabel.timestamp_utc, recoveryForShiftLabel.shift_start_utc]
}

function formatExplainerData(explainerData) {
  const compoundExplainerData = explainerData.map((explainer) => {
    if (explainer.metric === 'mean') {
      explainer.compound_label =
        explainer.range_label + ' ' + explainer.engineering_units
    } else {
      explainer.compound_label = explainer.range_label
    }
    return explainer
  })

  if (compoundExplainerData === undefined) {
    return ''
  }
  return compoundExplainerData
}

function decimalToPercentage(decimal) {
  if (decimal !== null) {
    return Math.round(decimal * 100) + '%'
  }
  return ''
}

function dataURItoPNGBlob(dataURI) {
  let byteString = atob(dataURI.split(',')[1])
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new File([ab], new Date().toISOString() + '-screenshot.png')
}

export {
  recoveryModelTrace,
  recoveryActualTrace,
  recoveryDailyPlanTrace,
  tagStatTrace,
  explainerShiftLabel,
  recoveryTimeStampFromLabel,
  formatExplainerData,
  tagStatsShadedAreaShape,
  tagStatsStandardDeviationUpperTrace,
  tagStatsStandardDeviationLowerTrace,
  shiftChangeShape,
  decimalToPercentage,
  dataURItoPNGBlob,
}
