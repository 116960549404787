import React, { Suspense, lazy } from 'react'
import { Switch } from 'react-router-dom'
import AppliedRoute from './components/AppliedRoute'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'
import { Spinner } from '@rio-tinto/pace-ui-components'

const Row7 = lazy(() => import('./pages/Row7'))
const Row8 = lazy(() => import('./pages/Row8'))
const Row9 = lazy(() => import('./pages/Row9'))
const Row10 = lazy(() => import('./pages/Row10'))
const UserGuide = lazy(() => import('./pages/UserGuide'))
const Home = lazy(() => import('./pages/Home'))
const LoginPage = lazy(() => import('./pages/LoginPage'))

export default ({ childProps }) => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <AppliedRoute path="/" exact component={Home} props={childProps} />
      <UnauthenticatedRoute
        path="/account/login"
        exact
        component={LoginPage}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/row7"
        exact
        component={Row7}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/row8"
        exact
        component={Row8}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/row9"
        exact
        component={Row9}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/row10"
        exact
        component={Row10}
        props={childProps}
      />
      <AuthenticatedRoute
        path="/user-guide"
        exact
        component={UserGuide}
        props={childProps}
      />
    </Switch>
  </Suspense>
)
