import React, { useEffect, useState, Suspense } from 'react'
import { Auth, Hub, Storage } from 'aws-amplify'
import { ThemeProvider } from 'styled-components'
import usePersistedState from './utils/usePersistedState'
import { v4 as uuidv4 } from 'uuid'
import Routes from './Routes'
import {
  dark,
  light,
  GlobalStyles,
  LayoutContainer,
  Footer,
  FeedbackForm,
  Spinner,
} from '@rio-tinto/pace-ui-components'
import PageHeader from './components/PageHeader'
import Aside from './components/Aside'
import { useHistory } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import postAPI from './utils/postAPI'
import { dataURItoPNGBlob } from './utils/dataHelpers'

function App() {
  const [theme, setTheme] = usePersistedState('theme', dark)
  const [collapsed, setCollapsed] = usePersistedState('collapsed', true)
  const toggleTheme = () => {
    setTheme(theme.title === 'light' ? dark : light)
  }
  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 500)
  }
  let history = useHistory()

  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [token, setToken] = useState(null)

  function getToken() {
    return Auth.currentSession()
      .then((session) => session)
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    async function checkUserSession() {
      try {
        await Auth.currentAuthenticatedUser().then((user) => {
          setIsAuthenticated(true)
        })
      } catch (e) {
        if (e !== 'The user is not authenticated') {
          toast(e)
        }
      }
      setIsAuthenticating(false)
    }
    checkUserSession()
  }, [])

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          setToken('granting...')
          getToken().then((userToken) => setToken(userToken.idToken.jwtToken))
          break
        case 'signOut':
          setToken(null)
          break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data)
          break
      }
    })
  }, [])

  const handleLogout = async () => {
    await Auth.signOut()
    console.log('Logging out')
    setIsAuthenticated(false)
    history.push('/account/login')
  }

  const handleFeedback = async (data) => {
    let feedbackFormData = new FormData()
    let headers = { 'Content-Type': 'multipart/form-data' }
    if ('screenshotFile' in data) {
      feedbackFormData.append('file', dataURItoPNGBlob(data.screenshotFile))
    }
    feedbackFormData.append('message', data.message)
    feedbackFormData.append('uuid', uuidv4())
    feedbackFormData.append('submitted', new Date().toISOString())
    postAPI('/api/v1/feedback', feedbackFormData, headers)
      .then((result) => {
        console.log(result)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const childProps = {
    isAuthenticated,
    setIsAuthenticated,
    theme,
  }

  return (
    <Suspense fallback={<Spinner />}>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <div className="App" style={{ background: theme.backgroundColor }}>
          <PageHeader
            theme={theme}
            toggleTheme={toggleTheme}
            isAuthenticated={isAuthenticated}
            handleLogout={handleLogout}
          />
          <LayoutContainer>
            <Aside
              isAuthenticated={isAuthenticated}
              collapsed={collapsed}
              toggleCollapsed={toggleCollapsed}
            />
            <Routes childProps={childProps} />
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              draggable
              pauseOnHover
            />
            <FeedbackForm onSubmit={(data) => handleFeedback(data)} />
          </LayoutContainer>
          <Footer />
        </div>
      </ThemeProvider>
    </Suspense>
  )
}

export default App
