import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import Amplify from 'aws-amplify'
import config from './aws-exports'
import { BrowserRouter as Router } from 'react-router-dom'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.aws_project_region,
    userPoolId: config.aws_user_pools_id,
    identityPoolId: config.aws_cognito_identity_pool_id,
    userPoolWebClientId: config.aws_user_pools_web_client_id,
    oauth: {
      domain: config.oauth.domain,
      scope: config.oauth.scope,
      redirectSignIn: config.oauth.redirectSignIn,
      redirectSignOut: config.oauth.redirectSignOut,
      responseType: config.oauth.responseType,
    },
  },
  API: {
    endpoints: config.api.endpoints,
  },
})

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
