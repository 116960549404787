import {subHours, subWeeks, addHours, addDays, addMilliseconds} from 'date-fns'
import { format, utcToZonedTime, zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz'

const formatInTimeZone = (date, fmt, tz) =>
  format(utcToZonedTime(date, tz), fmt, { timeZone: tz })

function formatUTC(timeStamp) {
  return formatInTimeZone(timeStamp, 'yyyy-MM-dd kk:mm:ss', 'UTC')
}

function formatTime(timeStamp) {
  return format(new Date(timeStamp), 'yyyy-MM-dd kk:mm:ss')
}

function stripTZ(timeStamp) {
  return timeStamp.replace(/[+-][\d:]+$/, '')
}

function subTwelveHours(timeStamp) {
  let utcTime = zonedTimeToUtc(timeStamp, 'UTC')
  let subtractedTime = subHours(utcTime, 12)
  return subtractedTime.toISOString().split('.').shift()
}

function addTwelveHours(timeStamp) {
  let utcTime = zonedTimeToUtc(timeStamp, 'UTC')
  let addedTime = addHours(utcTime, 12)
  return addedTime.toISOString().split('.').shift()
}

function subtractWeeks(timeStamp, weeks) {
  let utcTime = zonedTimeToUtc(timeStamp, 'UTC')
  let subtractedTime = subWeeks(utcTime, weeks)
  return subtractedTime.toISOString().split('.').shift()
}

function formatDateUTC(timeStamp) {
  let utcTime = zonedTimeToUtc(timeStamp, 'UTC')
  return utcTime.toISOString().split('.').shift()
}

function formatDateMT(timeStamp) {
  let utcTime = zonedTimeToUtc(timeStamp, 'UTC')
  // Need correct tz offset. MT changes between -7 (MST) and -6 (MDT) depending on daylight saving
  const tzOffsetMS = getTimezoneOffset('America/Denver', utcTime)
  let mtTime = addMilliseconds(utcTime, tzOffsetMS)
  return mtTime.toISOString().split('.').shift()
}

function formatTomorrowUTC(timeStamp) {
  let utcTime = zonedTimeToUtc(timeStamp, 'UTC')
  let addedTime = addDays(utcTime, 1)
  return addedTime.toISOString().split('.').shift()
}

export {
  formatUTC,
  formatTime,
  stripTZ,
  subTwelveHours,
  addTwelveHours,
  subWeeks,
  subtractWeeks,
  formatDateUTC,
  formatDateMT,
  formatTomorrowUTC,
}
