import { API, Auth } from 'aws-amplify'
import axios from 'axios'
import { toast } from 'react-toastify'

async function postAPI(path, data, headers = {}) {
  let result
  if (process.env.NODE_ENV === 'development') {
    result = await axios
      .post(path, data, {
        headers: headers,
      })
      .catch((error) => {
        if (error.response) {
          toast.error('API error: ' + error.response.data)
        }
        throw error
      })
    return result.data
  } else {
    result = API.post('rowRecoveryFeedbackAPI', path, {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        ...headers,
      },
      body: data,
    }).catch((error) => {
      if (error.response) {
        //TODO: Ensure this works for API Gateway responses
        toast.error('API error: ' + error.response.data)
      }
      throw error
    })
    return result
  }
}

export default postAPI
