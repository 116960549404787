import {
  DropMenu,
  DropMenuItem,
  Header,
  Logo,
  LogoBar,
  Settings,
  TopBar,
  Button,
} from '@rio-tinto/pace-ui-components'
import { Auth } from 'aws-amplify'
import { Col, Row } from 'react-flexbox-grid'
import Switch from 'react-switch'

const PageHeader = (props) => {
  const { theme, toggleTheme, isAuthenticated, handleLogout } = props

  return (
    <Header>
      <LogoBar>
        <Row>
          <Col xs={3}>
            <Logo href={'/'} />
          </Col>
          <Col xs={2} xsOffset={7}>
            <Settings label="Account">
              <DropMenu active trianglePosition="left">
                {!isAuthenticated ? (
                  <>
                    <DropMenuItem href={'/account/login'} icon="user">
                      Login
                    </DropMenuItem>
                    <Button
                      onClick={() =>
                        Auth.federatedSignIn({ provider: 'Cognito' })
                      }
                    >
                      Windows Login
                    </Button>
                  </>
                ) : (
                  <DropMenuItem icon="door-open" onClick={handleLogout}>
                    Logout
                  </DropMenuItem>
                )}
              </DropMenu>
            </Settings>
          </Col>
        </Row>
      </LogoBar>
      <TopBar>
        <Row>
          <Col xs={2} xsOffset={0}>
            {theme && (
              <Switch
                onChange={toggleTheme}
                checked={theme.title === 'dark'}
                checkedIcon={false}
                uncheckedIcon={false}
                height={10}
                width={40}
                handleDiameter={20}
                offColor={theme.brandColors.primaryRed}
                onColor={theme.brandColors.primaryLightGreen}
                onHandleColor={theme.brandColors.rioTintoLightGrey}
                offHandleColor={theme.brandColors.rioTintoLightGrey}
              />
            )}
          </Col>
        </Row>
      </TopBar>
    </Header>
  )
}

export default PageHeader
