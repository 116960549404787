import { MenuContainer, MenuItem, SideBar } from '@rio-tinto/pace-ui-components'

const Aside = (props) => {
  const { isAuthenticated, collapsed, toggleCollapsed } = props

  return (
    <SideBar collapsed={collapsed} onClick={toggleCollapsed}>
      <MenuContainer collapsed={collapsed}>
        {isAuthenticated && (
          <>
            <MenuItem
              icon={'chart-line'}
              href={'/row7'}
              title={'Row 7'}
              collapsed={collapsed}
            >
              Row 7
            </MenuItem>
            <MenuItem
              icon={'chart-line'}
              href={'/row8'}
              title={'Row 8'}
              collapsed={collapsed}
            >
              Row 8
            </MenuItem>
            <MenuItem
              icon={'chart-line'}
              href={'/row9'}
              title={'Row 9'}
              collapsed={collapsed}
            >
              Row 9
            </MenuItem>
            <MenuItem
              icon={'chart-line'}
              href={'/row10'}
              title={'Row 10'}
              collapsed={collapsed}
            >
              Row 10
            </MenuItem>
            <MenuItem
              icon={'question-circle'}
              href={'/user-guide'}
              title={'User Guide'}
              collapsed={collapsed}
            >
              User Guide
            </MenuItem>
          </>
        )}
      </MenuContainer>
    </SideBar>
  )
}

export default Aside
